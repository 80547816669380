import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Anchor, Heading, Paragraph } from 'grommet'

import { Layout } from '../layout'
import SEO from '../components/seo'

const StyleWrap = styled.div`
  position: relative;
  article {
    position: relative;
    width: 90%;
    z-index: 2;
  }
  .map-wrap {
    position: absolute;
    width: 50%;
    left: 40%;
    top: 50px;
    z-index: 1;
    @media screen and (max-width: 1000px) {
      position: static;
      width: 100%;
    }
  }
`

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "map.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Kontakt" />
      <Heading>Kontakt</Heading>
      <StyleWrap>
        <article>
          <Heading level={3} className="fill">
            Katedra Nanobiotechnologii
          </Heading>
          <Heading level={4}>Instytut Biologii</Heading>
          <Paragraph fill>
            Szkoła Główna Gospodarstwa Wiejskiego w Warszawie
          </Paragraph>
          <Paragraph>ul. Ciszewskiego 8</Paragraph>
          <Paragraph>02-786 Warszawa</Paragraph>
          <Paragraph>
            e-mail:{' '}
            <Anchor href="mailto:knano@sggw.edu.pl" label="knano@sggw.edu.pl" />
          </Paragraph>
          <Paragraph>tel./fax: +48 22 593 66 69</Paragraph>
        </article>
        <div className="map-wrap">
          <Img fluid={data.map.childImageSharp.fluid} />
        </div>
      </StyleWrap>
    </Layout>
  )
}

export default ContactPage
